export const usePropStore = defineStore("prop", () => {
  const totalProps = 6;
  const cookiePropIndex = useCookie("prop-index");

  const index = ref(Number(cookiePropIndex.value ?? 0));

  function getNewPropValue(current: number) {
    if (isNaN(current) || current === null || current === undefined) {
      return 0;
    }

    return current >= totalProps ? 0 : current + 1;
  }

  watch(index, (val) => {
    cookiePropIndex.value = val.toString();
  });

  function nextProp() {
    index.value = getNewPropValue(index.value);
  }

  // Set next prop on page load
  if (index.value === 0 && cookiePropIndex.value === undefined) {
    cookiePropIndex.value = '0';
  } else {
    nextProp();
  }

  return {
    index,
    nextProp,
  };
});